.bIg{
  /* background-image: linear-gradient(#8693AB, #BDD4E7); */
  background-image: linear-gradient(#3EADCF, #ABE9CD);
  background-image: linear-gradient(#ABE9CD, #395B64);
}


header {
	text-align: center;
	overflow: hidden;
	border-radius: 0 0 85% 85% / 30%;
}
header .overlay{
	padding: 10px;
	text-shadow: 1px 1px 1px #333;
  background-image: linear-gradient( 135deg, #28313b 10%, #395B64 100%);
	
}

.overlay>h1 {
	font-size: 50px;
	color: white;
}

.container:before{
		content: none;
}